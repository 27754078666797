import { cn } from "@/util";
import React, { useEffect, useRef } from "react";

type Props = {
    type?: string;
    name?: string;
    value?: string | number;
    defaultValue?: string | number;
    className?: string;
    autoComplete?: string;
    required?: boolean;
    isFocused?: boolean;
    handleChange?: (e: any) => void;
    disabled?: boolean;
};

export default function Input({
    type = "text",
    name,
    value,
    defaultValue,
    className,
    autoComplete,
    required = false,
    isFocused,
    handleChange,
    disabled,
}: Props) {
    const input = useRef<HTMLInputElement>();

    useEffect(() => {
        if (isFocused && input && input.current) {
            input.current.focus();
        }
    }, []);

    return (
        <div className="flex flex-col items-start">
            <input
                type={type}
                name={name}
                defaultValue={defaultValue}
                value={value}
                className={cn([
                    `rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring
                    focus:ring-indigo-200 focus:ring-opacity-50 ${className}`,
                ])}
                ref={input}
                autoComplete={autoComplete}
                required={required}
                onChange={(e) => handleChange(e)}
                disabled={disabled}
            />
        </div>
    );
}
